export const LOGO = "/images/logo.png";
export const CELLS_AREA = "/images/cellsArea.svg";
export const CLOSE_ICON = "/images/icons/closeIcon.svg";
export const NFT_ICONS = "/images/icons/nftIcons/";

export const TONWALLET_ICON = "/images/icons/tonwallet.svg";
export const TONKEEPER_ICON = "/images/icons/tonkeeper.svg";
export const TONHUB_ICON = "/images/icons/tonhub.svg";

export const AREA_MOCK = "/images/areaMock.jpg";
